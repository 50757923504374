import React from 'react'

import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import SEO from 'components/SEO/SEO'

import API from 'api'

import BillingHistoryTable from './components/BillingHistoryTable/BillingHistoryTable'

class BillingHistoryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            billingHistory: {
                isLoading: false,
                lastResponse: null,
                requestParams: {
                    size: 10,
                },
                list: [],
            },
        }
    }

    async componentDidMount() {
        await this.getBillingHistory()
        this.setState({
            isReady: true,
        })
    }

    getBillingHistory = async (page = 0, clear = true) => {
        try {
            const {
                user: { id },
            } = this.props
            const {
                billingHistory: { requestParams },
            } = this.state

            this.setState(prevState => ({
                billingHistory: {
                    ...prevState.billingHistory,
                    isLoading: true,
                },
            }))

            const response = await API.USERS.GET_PAYMENTS(id, {
                ...requestParams,
                page,
            })

            this.setState(prevState => ({
                billingHistory: {
                    ...prevState.billingHistory,
                    lastResponse: response,
                    list: clear ? response.content : [...prevState.billingHistory.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                billingHistory: {
                    ...prevState.billingHistory,
                    isLoading: false,
                },
            }))
        }
    }

    render() {
        const { userVisibleName } = this.props
        const { isReady, billingHistory } = this.state

        return (
            <>
                <SEO title={`Users / ${userVisibleName} / Billing History`} />

                {isReady ? (
                    <BillingHistoryTable
                        list={billingHistory.list}
                        pagination={{
                            currentPage: billingHistory.lastResponse.number,
                            totalPages: billingHistory.lastResponse.totalPages,
                            perPageElements: billingHistory.lastResponse.numberOfElements,
                            totalElements: billingHistory.lastResponse.totalElements,
                        }}
                        onExpand={() => this.getBillingHistory(billingHistory.lastResponse.number + 1, false)}
                        onChangePage={page => this.getBillingHistory(page)}
                        isLoading={billingHistory.isLoading}
                    />
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default BillingHistoryPage

import { useRef, useEffect } from 'react'
import classNames from 'classnames'

import styles from './RemixViewer.module.scss'

export const DEVICES = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
}

export const MODES = {
    PREVIEW: 'preview',
    PUBLISHED: 'published',
}

let REMIX = null

const RemixViewer = ({ projectStructureJson, device = DEVICES.DESKTOP }) => {
    const previewRef = useRef(null)

    useEffect(() => {
        if (projectStructureJson) {
            initProject()

            return () => {
                if (REMIX) REMIX.destroyIframe()
            }
        }
    }, [projectStructureJson])

    const initProject = async () => {
        if (!projectStructureJson) return
        REMIX = new window.RemixLoader({
            mode: MODES.PREVIEW,
            remixUrl: process.env.REACT_APP_ACCOUNT_URL + '/remix.html',
            nodeElement: previewRef.current,
            projectStructure: JSON.stringify(projectStructureJson),
            features: [
                'CUSTOM_CSS_STYLES',
                'GAMIFICATION',
                'SDK_INTEGRATION',
                'LEAD_COLLECTION_FORM',
                'GOOGLE_ANALYTICS',
            ],
        })

        REMIX.createIframe()
    }

    return (
        <div
            className={classNames(styles.iframeWrapper, {
                [styles.isDesktop]: device === DEVICES.DESKTOP,
                [styles.isMobile]: device === DEVICES.MOBILE,
            })}
        >
            <div className={styles.iframeInner}>
                <div ref={previewRef} />
            </div>
        </div>
    )
}

export default RemixViewer

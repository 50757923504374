import React, { useEffect, useState } from 'react'

import JsonView from 'components/JsonView/JsonView'
import TextArea from 'components/Forms/TextArea/TextArea'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'

import { fieldsValidator, getIsJsonStringRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

const JsonParseModal = ({ onClose = () => {} }) => {
    const [structureJson, setStructureJson] = useState('')
    const [jsonViewValue, setJsonViewValue] = useState(null)

    const [errors, setErrors] = useState({})

    useEffect(() => {
        setErrors({})

        if (structureJson === '') {
            setJsonViewValue(null)
            return
        }

        const validator = new fieldsValidator(false)
        validator.addFields([validator.createField('structureJson', structureJson, [getIsJsonStringRule()])])
        const { isHasErrors, errors } = validator.validate()
        if (isHasErrors) {
            setErrors(errors)
            setJsonViewValue(null)
            return
        }

        setJsonViewValue(JSON.parse(structureJson))
    }, [structureJson])

    return (
        <FormModal
            headText="Parse JSON"
            successText="Template created successfully!"
            actionText="Create"
            onClose={() => onClose()}
            isSuccessAutoClose={false}
            showActionButton={false}
            closeOnEsc
            closeOnOverlayClick
            width={960}
            cancelText="Close"
        >
            <FormBlock label="Input data">
                <TextArea
                    value={structureJson}
                    rows={8}
                    error={errors.structureJson}
                    onChange={value => {
                        setStructureJson(value)
                        setErrors(removeKeyFromObject(errors, 'structureJson'))
                    }}
                />
            </FormBlock>

            {jsonViewValue && (
                <FormBlock label="Parsed data">
                    <JsonView value={jsonViewValue} />
                </FormBlock>
            )}
        </FormModal>
    )
}

export default JsonParseModal

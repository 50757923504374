import React from 'react'

import Modal from 'components/Modal/Modal'
import RemixViewer from 'components/RemixViewer/RemixViewer'

import styles from './ProjectViewerModal.module.scss'

const ProjectViewerModal = ({ projectStructureJson, onClose = () => {} }) => (
    <Modal closeOnEsc onClose={onClose}>
        <div className={styles.projectViewer}>
            <div className={styles.closeIcon} onClick={() => onClose()} />
            <div className={styles.content}>
                <RemixViewer projectStructureJson={projectStructureJson} />
            </div>
        </div>
    </Modal>
)

export default ProjectViewerModal

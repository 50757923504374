import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import './styles/reset.scss'
import './styles/main.scss'

import 'react-toastify/dist/ReactToastify.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import ROUTES from 'constants/routes'

import SEO from 'components/SEO/SEO'
import Preloader from 'components/Preloader/Preloader'
import ModalManager from 'components/ModalManager/ModalManager'

import API from 'api'

import Error from 'pages/Error/Error'
import Auth from 'pages/Auth/Auth'
import Reports from 'pages/Reports/Reports'
import Users from 'pages/Users/Users'
import Organizations from 'pages/Organizations/Organizations'
import Organization from 'pages/Organization/Organization'
import User from 'pages/User/User'
import Projects from 'pages/Projects/Projects'
import BlocksTemplates from 'pages/BlocksTemplates/BlocksTemplates'
import EmailsSending from 'pages/EmailsSending/EmailsSending'
import TemplateGallery from 'pages/TemplateGallery/TemplateGallery'
import Translator from 'pages/Translator/Translator'
import Assets from 'pages/Assets/Assets'
import Authors from 'pages/Authors/Authors'
import UserContent from 'pages/UserContent/UserContent'
import Payouts from 'pages/Payouts/Payouts'
import ProjectGenerator from 'pages/ProjectGenerator/ProjectGenerator'

import { signIn } from 'store/actions/user'
import { ErrorRoute, AuthRoute, DashboardRoute } from 'routes'

import useScript from 'hooks/useScript'

import './global/polyfills'

const App = () => {
    useScript(`${process.env.REACT_APP_CDN_URL}/l.js`)

    const [isLoading, setIsLoading] = useState(true)
    const { isAuth } = useSelector(state => state.user)

    const dispatch = useDispatch()
    useEffect(() => {
        API.AUTH.GET_MY_DETAILS()
            .then(userDetailsData => {
                const data = {
                    user: { ...userDetailsData },
                }
                dispatch(signIn(data))
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) return <Preloader text="Authentication..." isFullScreen />

    return (
        <BrowserRouter>
            <SEO />
            <ModalManager />
            <Switch>
                {/* Auth routes */}
                <AuthRoute isAuth={isAuth} path={ROUTES.AUTH.path} component={Auth} />
                {/* Dashboard routes */}
                <DashboardRoute isAuth={isAuth} exact path={ROUTES.REPORTS.path} component={Reports} />
                <DashboardRoute isAuth={isAuth} exact path={ROUTES.USERS.path} component={Users} />
                <DashboardRoute isAuth={isAuth} path={`${ROUTES.USERS.path}/:id`} component={User} />
                <Redirect exact from={ROUTES.AUTHORS.path} to={`${ROUTES.AUTHORS.path}/tags`} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.AUTHORS.path} component={Authors} />
                <DashboardRoute isAuth={isAuth} exact path={ROUTES.ORGANIZATIONS.path} component={Organizations} />
                <DashboardRoute isAuth={isAuth} path={`${ROUTES.ORGANIZATIONS.path}/:id`} component={Organization} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.PROJECTS.path} component={Projects} />
                <Redirect
                    exact
                    from={ROUTES.TEMPLATE_GALLERY.path}
                    to={`${ROUTES.TEMPLATE_GALLERY.path}/interacty-templates`}
                />
                <DashboardRoute isAuth={isAuth} path={ROUTES.TEMPLATE_GALLERY.path} component={TemplateGallery} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.BLOCKS_TEMPLATES.path} component={BlocksTemplates} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.EMAILS_SENDING.path} component={EmailsSending} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.TRANSLATOR.path} component={Translator} />
                <Redirect exact from={ROUTES.ASSETS.path} to={`${ROUTES.ASSETS.path}/audio`} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.ASSETS.path} component={Assets} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.USERS_CONTENT.path} component={UserContent} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.PAYOUTS.path} component={Payouts} />
                <DashboardRoute isAuth={isAuth} path={ROUTES.PROJECT_GENERATOR.path} component={ProjectGenerator} />
                {/* Error routes */}
                <ErrorRoute path={ROUTES.ERROR.path} component={Error} />

                <Redirect exact from={ROUTES.HOME.path} to={ROUTES.USERS.path} />
                <Redirect
                    from="*"
                    to={{
                        pathname: ROUTES.ERROR.path,
                        state: {
                            errorCode: 404,
                        },
                    }}
                />
            </Switch>
            <ToastContainer />
        </BrowserRouter>
    )
}
export default App

import { cloneDeep } from 'lodash'

const BLOCKS = [
    {
        id: 1,
        name: 'Text',
    },
    {
        id: 2,
        name: 'Image',
    },
    {
        id: 3,
        name: 'Embed Project',
    },
    {
        id: 4,
        name: 'Flip Cards',
    },
    {
        id: 5,
        name: 'Youtube Video',
    },
    {
        id: 6,
        name: 'Button',
    },
    {
        id: 7,
        name: 'Interactive Image',
    },
    {
        id: 8,
        name: 'Hidden Objects',
    },
    {
        id: 9,
        name: 'Trivia Quiz',
    },
    {
        id: 10,
        name: 'Then/Now',
    },
    {
        id: 11,
        name: 'Memory Games',
    },
    {
        id: 12,
        name: 'Timeline',
    },
    {
        id: 13,
        name: 'Fortune Cookies',
    },
    {
        id: 14,
        name: 'Horoscope',
    },
    {
        id: 15,
        name: 'Personality Quiz',
    },
    {
        id: 16,
        name: 'Puzzle',
    },
    {
        id: 17,
        name: 'Lead Form',
    },
    {
        id: 18,
        name: 'Find Pair',
    },
    {
        id: 19,
        name: 'Slideshow',
    },
    {
        id: 20,
        name: 'Treasure Hunt',
    },
    {
        id: 21,
        name: 'Logo',
    },
    {
        id: 22,
        name: 'Matching',
    },
    {
        id: 23,
        name: 'Audio',
    },
    {
        id: 24,
        name: 'Embed IFrame',
    },
    {
        id: 25,
        name: 'Question',
    },
    {
        id: 26,
        name: 'Spin the Wheel',
    },
    {
        id: 27,
        name: 'Crossword',
    },
    {
        id: 28,
        name: 'Sliding Puzzle',
    },
    {
        id: 29,
        name: 'Rank Battle',
    },
]
export const getBlocks = () => cloneDeep(BLOCKS)
export const getBlockNameById = id => {
    const block = BLOCKS.find(b => b.id === id)
    return block ? block.name : null
}

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { cloneDeep } from 'lodash'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import FormModal from 'components/Modal/FormModal/FormModal'
import Preloader from 'components/Preloader/Preloader'
import FormBlock from 'components/Forms/FormBlock'
import Input from 'components/Forms/Input/Input'

import { LANGUAGE_TAG_PREFIX, ROLE_TAGS } from 'utils/tag'

import API from 'api'

import RemoveTagModal from '../RemoveTagModal/RemoveTagModal'

import ListView from '../ListView/ListView'

import { TABS } from './constants'

import styles from './TagsSelectionModal.module.scss'

const TagsSelectionModal = ({
    isShowRoles = false,
    initialSelectedTagsList = [],
    onAccept = () => {},
    onClose = () => {},
    isEnsureRemove,
    isForceSuccessClose = false,
    isShowOnlyEditable = true,
}) => {
    const [activeTab, setActiveTab] = useState(TABS.ALL)
    const [isLoadingTags, setIsLoadingTags] = useState(false)
    const [selectedTagsList, setSelectedTagsList] = useState(initialSelectedTagsList)
    const [tagsList, setTagsList] = useState([])
    const [freeTagsList, setFreeTagsList] = useState([])
    const [nameFilter, setNameFilter] = useState('')
    const [tagToRemove, setTagToRemove] = useState(null)

    useEffect(() => {
        setIsLoadingTags(true)
        API.TEMPLATES.GET_TAGS()
            .then(tags => {
                const data = cloneDeep(tags).filter(tag => {
                    if (!isShowOnlyEditable) return true
                    if (!isShowRoles && [ROLE_TAGS.BUSINESS, ROLE_TAGS.EDUCATION].includes(tag.name)) return false
                    if (tag.name.startsWith(LANGUAGE_TAG_PREFIX)) return false
                    return true
                })
                setTagsList(data.reverse())
            })
            .catch(err => {
                console.error(err)
                Toast(TOAST_TYPE.ERROR)
            })
            .finally(() => setIsLoadingTags(false))
    }, [])

    useEffect(() => {
        const selectedTagsListIds = selectedTagsList.map(tag => tag.id)
        setFreeTagsList(tagsList.filter(tag => !selectedTagsListIds.includes(tag.id)))
    }, [selectedTagsList, tagsList])

    const filtered = list =>
        list.filter(tag => {
            const isMatchFilter = tag.name.match(new RegExp(nameFilter, 'g'))
            if (isMatchFilter) {
                if (activeTab === TABS.ALL) return true
                if (activeTab === TABS.SYSTEM && tag.isSystem) return true
                if (activeTab === TABS.INTERESTS && tag.isInterests) return true
                if (activeTab === TABS.ROLES && [ROLE_TAGS.BUSINESS, ROLE_TAGS.EDUCATION].includes(tag.name))
                    return true
                if (activeTab === TABS.LANGUAGE && tag.name.startsWith(LANGUAGE_TAG_PREFIX)) return true
            }
            return false
        })

    return (
        <>
            <FormModal
                headText="Edit tags"
                successText="Tags updated successfully!"
                actionText="Update"
                isForceSuccessClose={isForceSuccessClose}
                successAutoCloseTime={1000}
                closeOnEsc={true}
                closeOnOverlayClick={true}
                onAction={async () => {
                    await onAccept(selectedTagsList)
                    return true
                }}
                onClose={() => onClose()}
            >
                <FormBlock>
                    <ul className={styles.tabs}>
                        <li
                            className={classNames(styles.tab, { [styles.isActiveTab]: activeTab === TABS.ALL })}
                            onClick={() => setActiveTab(TABS.ALL)}
                        >
                            All
                        </li>
                        <li
                            className={classNames(styles.tab, { [styles.isActiveTab]: activeTab === TABS.SYSTEM })}
                            onClick={() => setActiveTab(TABS.SYSTEM)}
                        >
                            System
                        </li>
                        {isShowRoles && (
                            <li
                                className={classNames(styles.tab, { [styles.isActiveTab]: activeTab === TABS.ROLES })}
                                onClick={() => setActiveTab(TABS.ROLES)}
                            >
                                Roles
                            </li>
                        )}
                        <li
                            className={classNames(styles.tab, { [styles.isActiveTab]: activeTab === TABS.INTERESTS })}
                            onClick={() => setActiveTab(TABS.INTERESTS)}
                        >
                            Interests
                        </li>
                        {!isShowOnlyEditable && (
                            <li
                                className={classNames(styles.tab, {
                                    [styles.isActiveTab]: activeTab === TABS.LANGUAGE,
                                })}
                                onClick={() => setActiveTab(TABS.LANGUAGE)}
                            >
                                Language
                            </li>
                        )}
                    </ul>
                </FormBlock>
                <FormBlock label="Name filter">
                    <Input isClearable value={nameFilter} onChange={value => setNameFilter(value)} />
                </FormBlock>
                <br />
                <FormBlock label="Selected tags">
                    <ListView
                        isShowEdit={false}
                        tagsList={filtered(selectedTagsList)}
                        onRemove={tagToRemove => {
                            if (tagToRemove.isSystem && isEnsureRemove) {
                                const initialSelectedTagsListIds = initialSelectedTagsList.map(tag => tag.id)
                                if (initialSelectedTagsListIds.includes(tagToRemove.id)) {
                                    setTagToRemove(tagToRemove)
                                    return
                                }
                            }
                            setSelectedTagsList(selectedTagsList.filter(tag => tag.id !== tagToRemove.id))
                        }}
                    />
                </FormBlock>
                {isLoadingTags ? (
                    <Preloader />
                ) : (
                    <FormBlock label="Other tags">
                        <ListView
                            isShowEdit={false}
                            isShowAdd={true}
                            isShowRemove={false}
                            tagsList={filtered(freeTagsList)}
                            onAdd={tagToAdd => setSelectedTagsList([...selectedTagsList, tagToAdd])}
                        />
                    </FormBlock>
                )}
            </FormModal>

            {!!tagToRemove && (
                <RemoveTagModal
                    isForceSuccessClose={true}
                    isSkipEnsure={true}
                    tag={tagToRemove}
                    onRemove={tagToRemove =>
                        setSelectedTagsList(selectedTagsList.filter(tag => tag.id !== tagToRemove.id))
                    }
                    onClose={() => setTagToRemove(null)}
                />
            )}
        </>
    )
}

export default TagsSelectionModal

import { cloneDeep } from 'lodash'

const ROUTES = {
    HOME: {
        path: '/',
        label: 'Home',
    },
    REPORTS: {
        path: '/reports',
        label: 'Reports',
    },
    USERS: {
        path: '/users',
        label: 'Users',
    },
    TEMPLATE_GALLERY: {
        path: '/template-gallery',
        label: 'Template gallery',
    },
    BLOCKS_TEMPLATES: {
        path: '/blocks-templates',
        label: 'Blocks templates',
    },
    AUTH: {
        path: '/auth',
        label: 'Auth',
    },
    PROJECTS: {
        path: '/projects',
        label: 'Projects',
    },
    EMAILS_SENDING: {
        path: '/emails-sending',
        label: 'Emails sending',
    },
    TRANSLATOR: {
        path: '/translator',
        label: 'Translator',
    },
    ORGANIZATIONS: {
        path: '/organizations',
        label: 'Organizations',
    },
    ASSETS: {
        path: '/assets',
        label: 'Assets',
    },
    AUTHORS: {
        path: '/authors',
        label: 'Authors',
    },
    USERS_CONTENT: {
        path: '/users-content',
        label: 'Users content',
    },
    PAYOUTS: {
        path: '/payouts',
        label: 'Payouts',
    },
    ERROR: {
        path: '/error',
        label: 'Error',
    },
    PROJECT_GENERATOR: {
        path: '/project-generator',
        label: 'Project Generator',
    },
}

export default cloneDeep(ROUTES)

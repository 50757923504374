import React from 'react'
import ReactDOM from 'react-dom'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import keydown from 'react-keydown'

import './Modal.scss'

const modalElement = document.getElementById('modal')

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.modalRef = React.createRef()
        this.el = document.createElement('div')
    }

    componentDidMount() {
        modalElement.appendChild(this.el)
        disableBodyScroll(this.modalRef.current, { reserveScrollBarGap: true })
    }

    componentWillUnmount() {
        modalElement.removeChild(this.el)
        clearAllBodyScrollLocks()
    }

    @keydown(['ESC'])
    escapePressed() {
        const { onClose, closeOnEsc = true } = this.props
        if (closeOnEsc) {
            onClose()
        }
    }

    render() {
        const { onClose, closeOnOverlayClick = true, children } = this.props

        return ReactDOM.createPortal(
            <div className="modal-wrapper" ref={this.modalRef} onClick={() => closeOnOverlayClick && onClose()}>
                <div className="modal-wrapper__container">
                    <div className="modal-wrapper__content" onClick={evt => evt.stopPropagation()}>
                        {children}
                    </div>
                </div>
            </div>,
            this.el,
        )
    }
}

export default Modal

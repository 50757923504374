import React from 'react'
import moment from 'moment'

import Table from 'components/Table/Table'

const getColumns = () => [
    {
        headerLabel: 'Date',
        getTitle: payment => moment(payment.saleDate).format('MMM D, YYYY'),
        getValue: payment => moment(payment.saleDate).format('MMM D, YYYY'),
        width: '15%',
    },
    {
        headerLabel: 'Invoice',
        getValue: payment => (
            <a
                href={`https://secure.2checkout.com/cpanel/order_info.php?refno=${payment.externalOrderReference}&agregate=0&timezone=custom`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {payment.externalOrderReference}
            </a>
        ),
        width: '15%',
    },
    {
        headerLabel: 'Description',
        getTitle: payment => payment.product.description.replace('<p>', '').replace('</p>', ''),
        getValue: payment => payment.product.description.replace('<p>', '').replace('</p>', ''),
        width: '25%',
    },
    {
        headerLabel: 'Payment method',
        getTitle: payment => payment.paymentMethod,
        getValue: payment => payment.paymentMethod,
        width: '20%',
    },
    {
        headerLabel: 'Status',
        getTitle: payment => payment.orderStatus,
        getValue: payment => payment.orderStatus,
        width: '15%',
    },
    {
        headerLabel: 'Total',
        getTitle: payment => `${payment.price} ${payment.currency}`,
        getValue: payment => `${payment.price} ${payment.currency}`,
        width: '10%',
    },
]

const BillingHistoryTable = ({ title, list, pagination, onExpand, onChangePage, isLoading }) => (
    <Table
        columns={getColumns()}
        title={title}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        isLoading={isLoading}
    />
)

export default BillingHistoryTable

import ROUTES from './routes'

const NAV_LIST = [
    {
        isVisible: true, // in future we can check user authority (from redux state) and give access to some menu items
        to: ROUTES.REPORTS.path,
        label: ROUTES.REPORTS.label,
    },
    {
        isVisible: true,
        to: ROUTES.USERS.path,
        label: ROUTES.USERS.label,
    },
    {
        isVisible: true,
        to: ROUTES.AUTHORS.path,
        label: ROUTES.AUTHORS.label,
        additionalProps: {
            isActive: (match, location) =>
                match || location.pathname.split('/')[1] === ROUTES.AUTHORS.path.split('/')[1],
        },
    },
    {
        isVisible: true,
        to: ROUTES.ORGANIZATIONS.path,
        label: ROUTES.ORGANIZATIONS.label,
    },
    {
        isVisible: true,
        to: ROUTES.PROJECTS.path,
        label: ROUTES.PROJECTS.label,
    },
    {
        isVisible: true,
        to: ROUTES.USERS_CONTENT.path,
        label: ROUTES.USERS_CONTENT.label,
    },
    {
        isVisible: true,
        to: ROUTES.PAYOUTS.path,
        label: ROUTES.PAYOUTS.label,
    },
    {
        isVisible: true,
        to: ROUTES.TEMPLATE_GALLERY.path,
        label: ROUTES.TEMPLATE_GALLERY.label,
        additionalProps: {
            isActive: (match, location) =>
                match || location.pathname.split('/')[1] === ROUTES.TEMPLATE_GALLERY.path.split('/')[1],
        },
    },
    {
        isVisible: true,
        to: ROUTES.BLOCKS_TEMPLATES.path,
        label: ROUTES.BLOCKS_TEMPLATES.label,
    },
    {
        isVisible: true,
        to: ROUTES.ASSETS.path,
        label: ROUTES.ASSETS.label,
        additionalProps: {
            isActive: (match, location) =>
                match || location.pathname.split('/')[1] === ROUTES.ASSETS.path.split('/')[1],
        },
    },
    {
        isVisible: true,
        to: ROUTES.EMAILS_SENDING.path,
        label: ROUTES.EMAILS_SENDING.label,
    },
    {
        isVisible: true,
        to: ROUTES.TRANSLATOR.path,
        label: ROUTES.TRANSLATOR.label,
    },
    {
        isVisible: true,
        to: ROUTES.PROJECT_GENERATOR.path,
        label: ROUTES.PROJECT_GENERATOR.label,
    },
]

export const getAllowedNavList = () => NAV_LIST.filter(item => item.isVisible)

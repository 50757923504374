import React, { useEffect, useState } from 'react'

import Button from 'components/Forms/Button/Button'
import Checkbox from 'components/Forms/Checkbox/Checkbox'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Modal from 'components/Modal/Modal'

import './ConfirmationDialog.scss'

const ConfirmationDialog = ({
    headText = 'Please confirm',
    successText = 'Success!',
    image = null,
    noteText,
    targetText,
    helpText,
    actionVariant = 'primary',
    actionText = 'Accept',
    cancelText = 'Cancel',
    isForceSuccessClose = false,
    isSuccessAutoClose = true,
    successAutoCloseTime = 1500,
    isLockClosing = false,
    closeOnEsc = true,
    closeOnOverlayClick = true,
    showCancelButton = true,
    isShowEnsure = false,
    ensureText = 'Are you sure?',
    onAction,
    onClose,
}) => {
    const [isEnsure, setIsEnsure] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    useEffect(() => {
        let timer = null
        if (isSuccess) {
            if (isForceSuccessClose) {
                onClose()
                return
            }
            if (isSuccessAutoClose) timer = setTimeout(() => onClose(), successAutoCloseTime)
        }
        return () => clearTimeout(timer)
    }, [isSuccess])

    return (
        <Modal
            closeOnEsc={!isLockClosing || closeOnEsc}
            closeOnOverlayClick={!isLockClosing || closeOnOverlayClick}
            onClose={!isLockClosing ? onClose : () => {}}
        >
            <div className="confirmation-dialog">
                {!isLockClosing && <div className="confirmation-dialog__close" onClick={() => onClose()}></div>}
                {isSuccess ? (
                    <>
                        <div className="confirmation-dialog__success">{successText}</div>
                    </>
                ) : (
                    <>
                        <h2 className="confirmation-dialog__head">{headText}</h2>
                        {image && (
                            <div className="confirmation-dialog__image">
                                <img src={image} alt="" />
                            </div>
                        )}
                        {noteText && <div className="confirmation-dialog__note">{noteText}</div>}
                        {targetText && <div className="confirmation-dialog__target">{targetText}</div>}
                        {helpText && <div className="confirmation-dialog__help">{helpText}</div>}
                        {isShowEnsure && (
                            <div className="confirmation-dialog__ensure">
                                <Checkbox value={isEnsure} label={ensureText} onChange={value => setIsEnsure(value)} />
                            </div>
                        )}
                        <div className="confirmation-dialog__buttons">
                            {showCancelButton && (
                                <Button content={cancelText} variant="secondary" onClick={() => onClose()} />
                            )}
                            <Button
                                content={actionText}
                                variant={actionVariant}
                                isDisabled={isLoading || (isShowEnsure && !isEnsure)}
                                isLoading={isLoading}
                                onClick={async () => {
                                    try {
                                        setIsLoading(true)
                                        await onAction()
                                        setIsSuccess(true)
                                    } catch (err) {
                                        console.error(err)
                                        Toast(TOAST_TYPE.ERROR)
                                    } finally {
                                        setIsLoading(false)
                                    }
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default ConfirmationDialog
